import React from 'react'
import Layout from '../templates/layout'
import Hero from '../components/hero'
import Strapline from '../components/strapline'
import PageHelmet from '../components/page-helmet'
import Img from 'gatsby-image'
import {useIsScrolled} from '../helpers/hooks'
import {graphql} from 'gatsby'
import {unblockPortableText} from '../helpers/sanity'
import {localiseObject} from '../helpers/locale'
import {SITE_URL} from '../helpers/constants'
import '../scss/pages/index.scss'

export const query = graphql`

  query NetworkPageQuery {
    sanityNetworkPage {
      id
      pageSeo {
        title {
          en
          fr
        }
        description {
          en
          fr
        }
      }
      hero {
        poster {
          asset {
            fluid(maxWidth: 1500) {
              ...GatsbySanityImageFluid
            }
            fixed(width: 1200) {
              src
            }
          }
        }
      }
      _rawHero
      _rawIntro
      _rawIconShowcase
      _rawProcess
      _rawPortraitImage
      _rawCasestudyList(resolveReferences: {maxDepth: 4})
    }
  }
`

export default function Network ({data}) {

  const isScrolled = useIsScrolled()
  const {sanityNetworkPage: {pageSeo, hero, _rawHero, _rawIntro}} = data

  return (
    <Layout>
      <PageHelmet
        url={`${SITE_URL}/network`}
        pageSeo={pageSeo}
        description={_rawIntro.body}
        imageUrl={hero?.poster?.asset?.fixed?.src}
      />
      {_rawHero &&
        <Hero fill={isScrolled} straplineLeft={true}>
          <Img fluid={hero.poster.asset.fluid} />
          <Strapline
            heading={localiseObject(_rawHero.title)}
            text={unblockPortableText(localiseObject(_rawHero.body))}
          />
        </Hero>
      }
    </Layout>
  )
}